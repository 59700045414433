<template>
  <div class="page-stat2">
    <el-row type="flex" justify="space-between" align="center">
      <el-col>
        <el-page-header content="Маркетолог" title="на главную" @back="$router.push('/')"></el-page-header>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-form>
      <el-row>
        <el-col :span="7">
          <el-form-item label="Период">
            <el-date-picker v-model="period" type="daterange" unlink-panels @change="getMarketolog(period)">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('month')">Месяц</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('week')">Неделя</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="changeDate('day')">День</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-divider></el-divider>




    <el-table show-summary stripe style="width: 100%; margin-bottom: 20px;" :data="marketolog">
      <el-table-column prop="name">
      </el-table-column>
      <el-table-column prop="in" label="Вошло">
      </el-table-column>
      <el-table-column prop="inwork" label="В работе">
      </el-table-column>
      <el-table-column prop="success" label="Успешно">
      </el-table-column>
      <el-table-column prop="fail" label="Провал операторов">
      </el-table-column>
      <el-table-column prop="sl" label="Перевозчики + СЛ">
      </el-table-column>
      <el-table-column prop="noclient" label="Не клиент">
      </el-table-column>
      <el-table-column prop="spam" label="СПАМ / Повтор / НН">
      </el-table-column>
    </el-table>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-descriptions class="stat" title="Платный трафик" :column="2" size="medium" border>
          <el-descriptions-item>
            <template slot="label">
              Лидов вошло
            </template>
            {{direct.sum('in')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Брак
            </template>
            {{direct.sum('brak')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              В работе
            </template>
            {{direct.sum('inwork')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Провалено
            </template>
            {{direct.sum('fail')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Передано
            </template>
            {{direct.sum('logist')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Конверсия
            </template>
            {{parseFloat(direct.sum('logist')/(direct.sum('in')-direct.sum('brak'))*100).toFixed(2)}}%
          </el-descriptions-item>
<!--          <el-descriptions-item>-->
<!--            <template slot="label">-->
<!--              Закрыто всего-->
<!--            </template>-->
<!--            {{stat.direct.CLOSED_ALL}}-->
<!--          </el-descriptions-item>-->
<!--          <el-descriptions-item>-->
<!--            <template slot="label">-->
<!--              Закрыто прошлых-->
<!--            </template>-->
<!--            {{stat.direct.CLOSED_LAST}}-->
<!--          </el-descriptions-item>-->
<!--          <el-descriptions-item>-->
<!--            <template slot="label">-->
<!--              Отклонение от средней-->
<!--            </template>-->
<!--            {{stat.direct.DIVERGENCE}}-->
<!--          </el-descriptions-item>-->
          <el-descriptions-item>
            <template slot="label">
              Чистота
            </template>
            {{(100 - (direct.sum('brak') / direct.sum('in') * 100)).toFixed(2)}}%
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Расходы директ
            </template>
            <span v-if="direct.length">{{direct[0].sum}}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              CPL чистая
            </template>
            <span v-if="direct.length">{{(direct[0].sum/direct.sum('logist')).toFixed(2)}}</span>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="6">
        <el-descriptions class="stat" title="ГЦК" :column="2" size="medium" border>
          <el-descriptions-item>
            <template slot="label">
              Лидов вошло
            </template>
            {{gck.sum('in')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Брак
            </template>
            {{gck.sum('brak')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              В работе
            </template>
            {{gck.sum('inwork')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Провалено
            </template>
            {{gck.sum('fail')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Передано
            </template>
            {{gck.sum('logist')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Конверсия
            </template>
            {{parseFloat(gck.sum('logist')/(gck.sum('in')-gck.sum('brak'))*100).toFixed(2)}}%
          </el-descriptions-item>
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Закрыто всего-->
          <!--            </template>-->
          <!--            {{stat.direct.CLOSED_ALL}}-->
          <!--          </el-descriptions-item>-->
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Закрыто прошлых-->
          <!--            </template>-->
          <!--            {{stat.direct.CLOSED_LAST}}-->
          <!--          </el-descriptions-item>-->
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Отклонение от средней-->
          <!--            </template>-->
          <!--            {{stat.direct.DIVERGENCE}}-->
          <!--          </el-descriptions-item>-->
        </el-descriptions>
      </el-col>
      <el-col :span="6">
        <el-descriptions class="stat" title="Остальной трафик" :column="2" size="medium" border>
          <el-descriptions-item>
            <template slot="label">
              Лидов вошло
            </template>
            {{other.sum('in')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Брак
            </template>
            {{other.sum('brak')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              В работе
            </template>
            {{other.sum('inwork')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Провалено
            </template>
            {{other.sum('fail')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Передано
            </template>
            {{other.sum('logist')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Конверсия
            </template>
            {{parseFloat(other.sum('logist')/(other.sum('in')-other.sum('brak'))*100).toFixed(2)}}%
          </el-descriptions-item>
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Закрыто всего-->
          <!--            </template>-->
          <!--            {{stat.direct.CLOSED_ALL}}-->
          <!--          </el-descriptions-item>-->
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Закрыто прошлых-->
          <!--            </template>-->
          <!--            {{stat.direct.CLOSED_LAST}}-->
          <!--          </el-descriptions-item>-->
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Отклонение от средней-->
          <!--            </template>-->
          <!--            {{stat.direct.DIVERGENCE}}-->
          <!--          </el-descriptions-item>-->
          <el-descriptions-item>
            <template slot="label">
              Чистота
            </template>
            {{(100 - (other.sum('brak') / other.sum('in') * 100)).toFixed(2)}}%
          </el-descriptions-item>
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              CPL чистая-->
          <!--            </template>-->
          <!--          </el-descriptions-item>-->
        </el-descriptions>
      </el-col>
      <el-col :span="6">
        <el-descriptions class="stat" title="Всего" :column="2" size="medium" border>
          <el-descriptions-item>
            <template slot="label">
              Лидов вошло
            </template>
            {{all.sum('in')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Брак
            </template>
            {{all.sum('brak')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              В работе
            </template>
            {{all.sum('inwork')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Провалено
            </template>
            {{all.sum('fail')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Передано
            </template>
            {{all.sum('logist')}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Конверсия
            </template>
            {{parseFloat(all.sum('logist')/(all.sum('in')-all.sum('brak'))*100).toFixed(2)}}%
          </el-descriptions-item>
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Закрыто всего-->
          <!--            </template>-->
          <!--            {{stat.direct.CLOSED_ALL}}-->
          <!--          </el-descriptions-item>-->
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Закрыто прошлых-->
          <!--            </template>-->
          <!--            {{stat.direct.CLOSED_LAST}}-->
          <!--          </el-descriptions-item>-->
          <!--          <el-descriptions-item>-->
          <!--            <template slot="label">-->
          <!--              Отклонение от средней-->
          <!--            </template>-->
          <!--            {{stat.direct.DIVERGENCE}}-->
          <!--          </el-descriptions-item>-->
          <el-descriptions-item>
            <template slot="label">
              Чистота
            </template>
            {{(100 - (all.sum('brak') / all.sum('in') * 100)).toFixed(2)}}%
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              Расходы директ
            </template>
            <span v-if="direct.length">{{direct[0].sum}}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              CPL чистая
            </template>
            <span v-if="direct.length">{{(direct[0].sum/direct.sum('logist')).toFixed(2)}}</span>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>




















  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

Array.prototype.sum = function(key) {
  return this.reduce((p, c) => p + c[key], 0);
}
export default {
  data() {
    return {
      period: [moment().subtract(2, "weeks"), moment()]
    }
  },
  created() {
    this.getMarketolog(this.period);
  },
  methods: {
    ...mapActions(['getMarketolog', 'bxUsers']),
    changeDate(type) {
      const periods = {
        month: [moment().subtract('1', 'month').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
        week: [moment().subtract('1', 'week').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
        day: [moment().subtract('1', 'day').set({ h: 0, m: 0, s: 0 }), moment().subtract('1', 'day').set({ h: 23, m: 59, s: 59 })],
      }
      this.getMarketolog(periods[type])
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Итого";
          console.log(data);
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 'N/A';
        }
      })
      sums[5] = (sums[3] / sums[1] * 100).toFixed(2);
      sums[6] = (sums[4] / sums[1] * 100).toFixed(2);
      return sums;
    }
  },
  computed: {
    ...mapGetters(["marketolog", 'stat']),
    gck() {
      return this.marketolog.filter(e => e.name.indexOf('ГЦК') > -1)
    },
    direct() {
      return this.marketolog.filter(e => e.name.indexOf('Директ') > -1)
    },
    other() {
      return this.marketolog.filter(e => e.name.indexOf('Директ') == -1 && e.name.indexOf('ГЦК') == -1)
    },
    all() {
      return this.marketolog
    }
  }
}
</script>

<style>
.page-stat2 {
  padding: 40px;
}

.el-row {
  margin-bottom: 20px;
}
</style>